<template>
  <div class="Fence">
    <swiper :banners="banners"></swiper>
    <div class="Fence0">
      <breadcrumb :refer="refer"></breadcrumb>
      <div class="Fences">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { GetBanner } from '@/api/Home.js'
import swiper from "@/components/Swiper.vue";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import contactUs from "@/components/contactUs/contactUs.vue";

export default {
  name: "Fence",
  data() {
    return {
      refer: [
        { name: "首页", href: "/" },
        { name: "习剑" },
      ],
      banners: [],                // !banner图
      dataxinshow: false,
    };
  },
  mounted(){
    GetBanner().then(res=>{
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth > 750){
        this.banners = res.data.library;
      }
    })
  },
  components: {
    breadcrumb,
    swiper,
    contactUs
  },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.Fence0{margin: 0 auto;max-width: 1200px;}
@media (max-width: 750px) {
  .Fence0{padding: 0 vw(30);}
}
</style>
